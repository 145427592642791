import { Injectable } from '@angular/core';

@Injectable()
export class LeadFormModel {
    private _ApiKey = 'efb2ca4719df7d46490e40485ff65c00';
    public ZipCode: string = null;
    public MonthlyContribution: string = null;
    public Term: string = null;
    public annualIncome: string = null;
    public desiredIncome: string = null;
    public occupation: string = null;
    public AgeToTakeIncome: string = null;
    public Goal: string = null;
    public Gender : string = null;
    public Smoker : string = null;
    public Medication : string = null;
    public Dob : string = null;
    public Citizen : string = null;
    public Family : string = null;
    public Weight : string = null;
    public HeightInFeet : number = null;
    public HeightInInch : number = null;
    public HealthConditions: any[] = [];
    public RetirementPlans: any[] = [];
    public GUID: string = null;

    public CoverageAmount:string = null;

    public FirstName: string = null;
    public LastName: string = null;
    public Email: string = null;
    public PhoneNumber: string = null;
    public lead_id: string = null;

     // for address
     public Address: string = null;
     public City: string = null;
     public State: string = null;
     public Country: string = null;
 

    public get ApiKey(){
      return this._ApiKey;
    }
    public getZipCode() {
        return this.ZipCode;
    }

    public setZipCode(value) {
        this.ZipCode= value;
    }

    public getAnnualIncome() {
        return this.annualIncome;
    }

    public setAnnualIncome(value) {
        this.annualIncome = value;
    }

    public getDesiredIncome() {
        return this.desiredIncome;
    }

    public setDesiredIncome(value) {
        this.desiredIncome = value;
    }

    public getOccupation() {
        return this.occupation;
    }

    public setOccupation(value) {
        this.occupation = value;
    }

    public setGender(value) {
        this.Gender= value;
    }

    public setDob(value) {
        this.Dob = value;
    }

    public getDob() {
        return this.Dob;
    }

    public SetCitizen(value) {
        this.Citizen = value;
    }

    public SetFamily(value) {
        this.Family = value;
    }

    public getMonthlyContribution() {
        return this.MonthlyContribution;
    }

    public setMonthlyContribution(value) {
        this.MonthlyContribution = value;
    }

    public setHealthCondition(value) {
        this.HealthConditions.push(value);
    }

    public resetHealthCondition() {
        this.HealthConditions = [];
    }

    public setRetirementPlans(value) {
        this.RetirementPlans.push(value);
    }

    public resetRetirementplans() {
        this.RetirementPlans = [];
    }

    public setSmoker(value){
        this.Smoker = value;
    }

    public getTerm() {
        return this.Term;
    }

    public setTerm(value) {
        this.Term = value;
    }

    public getGoal() {
        return this.Goal;
    }

    public setGoal(value) {
        this.Goal= value;
    }

    public setMedication(value) {
        this.Medication= value;
    }

    public getWeight() {
        return this. Weight;
    }

    public setWeight(value) {
        this.Weight = value;
    }

    public getHeightInFeet() {
        return this.HeightInFeet;
    }

    public setHeightInFeet(value) {
        this.HeightInFeet = value;
    }

    public getHeightInInch() {
        return this.HeightInInch;
    }

    public setHeightInInch(value) {
        this.HeightInInch = value;
    }

}
