import { environment } from '../../environments/environment';

export const GET_QUOTE_URL = environment.url + 'spa/quoteList';
export const ADD_LEAD_URL = environment.url + 'spa/save-spa-leads';
export const SEND_QUOTE_URL = environment.url + 'spa/send-spa-quotes';
export const GET_DO_I_QUALIFY_QUESTIONS_URL = environment.url + 'spa/carrier-questions';
export const GET_SPA_CONTENT_URL = environment.url + 'spa/noexam-spa-api/bed772bef667754e0655f745dbdb4adc';
export const Check_Now= environment.url + 'spa/carrier-answers';
export const SEND_Me_Application = environment.url + 'spa/update-spa-leads';
export const Policy_Create = environment.url + 'spa/save-policy';
export const UTM_URL = environment.url + 'spa/save-spa-utm';
export const Retirement_Plan_URL = environment.url + 'spa/save-retirement-plans';